
import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { grades, tableWidths } from '../../constants'
import CommonUtils from '@/utils/common-utils';
import StudentUtils from '@/utils/student-utils';
import Confirm from '@/components/core/Confirm.vue';
import draggable from 'vuedraggable';
import SchoolYearSelector from '../core/SchoolYearSelector.vue';
import RosteredItemIndicator from '@/components/integration/RosteredItemIndicator.vue';
import ld from 'lodash';
import moment from 'moment';

const studentAssignment = namespace('studentassignment');
const classes = namespace('classes');
const teachers = namespace('teachers');
const index = namespace('index');
const settings = namespace('settings');
const integration = namespace('integration');

@Component({
  components: {
    draggable,
    SchoolYearSelector,
    RosteredItemIndicator
  }
})
export default class StudentAssignment extends Vue {
  @Prop()
  showLevel!: any

  @Prop({ type: Boolean })
  value!: boolean;

  @Prop()
  selectedRemoveSubjectId!: number

  @index.Action
  disableResizeable!: () => Promise<any>;

  @index.Action
  enableResizeable!: () => Promise<any>;

  $refs!: {
      confirm: Confirm
  }

  // FOR ADMIN
  @studentAssignment.Action
  getStudentTeacherAssignment!: (param?: any) => Promise<any>;

  @studentAssignment.Action
  removeStudentFromTeacher!: (param?: any) => Promise<any>;

  @studentAssignment.Action
  assignStudentToTeacher!: (param?: any) => Promise<any>;

  @studentAssignment.Action
  removeAllStudentsFromTeacher!: (param?: any) => Promise<any>;

  @studentAssignment.Action
  assignAllStudentsToTeacher!: (param?: any) => Promise<any>;

  // FOR TEACHER
  @studentAssignment.Action
  getstudentSubjectAssignment!: (param?: any) => Promise<any>;

  @studentAssignment.Action
  assignStudentToClass!: (param?: any) => Promise<any>;

  @studentAssignment.Action
  removeStudentFromClass!: (param?: any) => Promise<any>;

  @studentAssignment.Action
  assignAllStudentsToClass!: (param?: any) => Promise<any>;

  @studentAssignment.Action
  removeAllStudentsFromClass!: (param?: any) => Promise<any>;

  @classes.Getter('getClassItems')
  classList!: Array<any>;

  @teachers.Getter
  getCurrentAdminItem!: any;

  @settings.Getter('getBrowserWidth')
  browserWidth!: number;

  @settings.Getter('getPrimarySchool')
  primarySchool!: any;

  @teachers.Getter('getAdminYearItems')
  getAdminYearItems!: (teacherId: number) => any[];

  @index.Getter
  hasBanner!: boolean;

  @integration.Getter
  haveRosteredItems!: boolean;

  @integration.Getter
  isStudentEnrollmentRostered!: (param: any) => boolean;

  headers = [
    { text: this.$t('lastNameLabel'), value: 'lastName', width: 120 },
    { text: this.$t('firstNameLabel'), value: 'firstName', width: 120 },
    { text: this.$t('typeLabel'), value: 'authorType', width: 120 },
    { text: '', value: 'roster-indicator', width: tableWidths.shortText, align: 'start', sortable: false }
  ];

  searchUnassigned = '';
  searchAssigned = '';
  assignedStudents:any = [];
  unassignedStudents:any = [];
  selectedAssignedRows:any = [];
  selectedUnassignedRows:any = [];
  selectedClass: any = null;
  localIsMenuOverlapping = false;
  lastBrowserWidth = 0;

  get filteredHeaders() {
    return this.headers.filter((a) => {
      if ((a.value === 'roster-indicator' && this.$currentUser.isAdmin) || (a.value === 'roster-indicator' && !this.haveRosteredItems)) {
        return false;
      }
      return true;
    });
  }

  get gradesList(): any {
    return grades;
  }

  get mobileLayout() {
    return this.$vuetify.breakpoint.smAndDown;
  }

  get horizontalLayout() {
    return this.browserWidth > 1009;
  }

  get wrapperHeight() {
    if (this.horizontalLayout) return this.hasBanner ? 'calc(100vh - 200px)' : 'calc(100vh - 120px)';
    if (this.mobileLayout) return this.hasBanner ? 'calc(50vh - 205px)' : 'calc(50vh - 115px)';
    return 'calc(50vh - 70px)';
  }

  get tableHeight() {
    if (this.horizontalLayout) return this.hasBanner ? 'calc(100vh - 320px)' : 'calc(100vh - 240px)';
    if (this.mobileLayout) return this.hasBanner ? 'calc(50vh - 325px)' : 'calc(50vh - 235px)';
    return 'calc(50vh - 190px)';
  }

  get currentYearId(): number {
    return +this.$store.state.settings.currentYearId;
  }

  get currentTeacherId(): number {
    return +this.$store.state.settings.currentTeacherId;
  }

  get schoolYearItems() {
    return this.getAdminYearItems(+this.currentTeacherId);
  }

  getStudentLabel(student: any) {
    return StudentUtils.getStudentLabel(student);
  }

  doneAssigning() {
    this.$emit('done');
  }

  @Watch('value')
  async loadStudents() {
    if (this.value) {
      CommonUtils.showLoading();
      await this.init();
    }
  }

  @Watch('selectedClass')
  async loadStudentsForSubject() {
    if (!this.selectedClass) {
      CommonUtils.hideLoading();
      return;
    }
    CommonUtils.showLoading();
    const payload: any = { subjectId: this.selectedRemoveSubjectId ? this.selectedRemoveSubjectId : this.selectedClass.value };
    if (this.selectedRemoveSubjectId) {
      this.selectedClass = this.classList.find(subject => subject.value === this.selectedRemoveSubjectId);
      this.$emit('remove-selected-id');
    }
    if (this.showLevel) payload.gradeLevel = this.showLevel.value;
    if (payload.gradeLevel === '-999') payload.gradeLevel = '';
    return this.getstudentSubjectAssignment(payload).then((d) => {
      const allStudents = d.data.students.map((s:any) => {
        s.subjectId = this.selectedRemoveSubjectId ? this.selectedRemoveSubjectId : this.selectedClass.value;
        s.isItemRostered = this.isStudentEnrollmentRostered(s);
        return s;
      });
      this.assignedStudents = [];
      this.unassignedStudents = [];
      for (const i in allStudents) {
        if (allStudents[i].inSubject === 'Y') {
          if (!this.assignedStudents.some((student: any) => allStudents[i].studentId === student.studentId)) {
            this.assignedStudents.push(allStudents[i]);
          }
        } else {
          if (!this.unassignedStudents.some((student: any) => allStudents[i].studentId === student.studentId)) {
            this.unassignedStudents.push(allStudents[i]);
          }
        }
      }
      CommonUtils.hideLoading();
    })
  }

  @Watch('showLevel')
  changeLevel() {
    if (this.$currentUser.isTeacher) {
      this.loadStudentsForSubject();
    } else {
      this.loadStudentsForTeacher();
    }
  }

  @Watch('browserWidth')
  onBrowserWidthChanged(val:any) {
    this.isMenuOverlapping(val);
  }

  updateShowLevel(newValue: any) {
    this.$emit('update:showLevel', newValue);
  }

  created() {
    if (this.$currentUser.isTeacher) {
      this.selectedClass = this.classList[0];
    } else {
      this.$nextTick(() => {
        this.checkForPastYears();
      })
      this.loadStudentsForTeacher();
    }
    this.$nextTick(() => {
      this.$eventBus.$on('userModeChanged', this.init);
      this.$eventBus.$on('schoolYearChanged', () => {
        this.init();
        this.checkForPastYears();
      })
    });
  }

  beforeDestroy() {
    this.$eventBus.$off('userModeChanged', this.init);
    this.$eventBus.$off('schoolYearChanged', this.init);
  }

  get yearSelectedIsPast() {
    for (let x = 0; x < this.schoolYearItems.length; x++) {
      if (this.schoolYearItems[x].value === this.currentYearId) {
        const lastDay = moment(this.schoolYearItems[x].lastDay || '12/31/9999', 'MM/DD/YYYY');
        return lastDay.isSameOrBefore(moment());
      }
    }
    return false;
  }

  checkForPastYears() {
    if (this.yearSelectedIsPast) {
      this.$refs.confirm?.alert({
        title: this.$t('assignStudentsLabel'),
        text: this.$t('assignStudentsWarning'),
        option1ButtonAlternativeText: this.$t('okLabel')
      })
    }
  }

  async init() {
    if (this.$currentUser.isTeacher) {
      await this.loadStudentsForSubject();
    } else {
      await this.loadStudentsForTeacher();
    }
    this.selectedAssignedRows = [];
    this.selectedUnassignedRows = [];
    return Promise.resolve();
  }

  async loadStudentsForTeacher() {
    CommonUtils.showLoading();
    const payload: any = {
      teacherId: this.getCurrentAdminItem.teacherId,
      yearId: this.getCurrentAdminItem.year.yearId,
      currentSchoolId: (this.$store.state.teachers.currentSchoolId || this.primarySchool.schoolId),
      gradeLevel: (this.showLevel && this.showLevel.value !== '-999') ? this.showLevel.value : ''
    }
    return this.getStudentTeacherAssignment(payload).then((d) => {
      this.assignedStudents = [];
      this.unassignedStudents = [];
      const allStudents = d.data.students.map((s:any) => {
        if (this.$currentUser.isTeacher) {
          s.subjectId = this.selectedRemoveSubjectId ? this.selectedRemoveSubjectId : this.selectedClass.value;
          s.isItemRostered = this.isStudentEnrollmentRostered(s);
        }
        return s;
      });
      for (const i in allStudents) {
        if (allStudents[i].inTeacher === 'Y') {
          this.assignedStudents.push(allStudents[i]);
        } else {
          this.unassignedStudents.push(allStudents[i]);
        }
      }
      CommonUtils.hideLoading();
    })
  }

  assignAll() {
    let warning = '';
    if (this.$currentUser.isAdmin) {
      warning = this.$t('assignAllTeacherWarning').toString()
    } else {
      warning = this.$t('assignAllClassWarning', { class: this.selectedClass.text }).toString()
    }

    const that = this;
    this.$refs.confirm.confirm({
      title: this.$t('assignAllLabel'),
      text: warning,
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result) => {
      if (result === 1) {
        if (that.$currentUser.isAdmin) {
          that.assignAllStudentsToTeacher({
            teacherId: that.getCurrentAdminItem.teacherId,
            yearId: that.getCurrentAdminItem.year.yearId,
            gradeLevel: (that.showLevel.value !== '-999') ? that.showLevel.value : ''
          });
        } else {
          that.assignAllStudentsToClass({
            subjectId: that.selectedClass.value,
            gradeLevel: (that.showLevel.value !== '-999') ? that.showLevel.value : ''
          })
        }
        that.assignedStudents = that.assignedStudents.concat(that.unassignedStudents);
        this.selectedUnassignedRows = [];
        that.unassignedStudents = [];
      }
    });
  }

  removeAll() {
    let warning = '';
    if (this.$currentUser.isAdmin) {
      warning = this.$t('removeAllTeacherWarning').toString()
    } else {
      warning = this.$t('removeAllClassWarning', { class: this.selectedClass.text }).toString()
    }

    const that = this;
    this.$refs.confirm.confirm({
      title: this.$t('removeAllLabel'),
      text: warning,
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result) => {
      if (result === 1) {
        if (that.$currentUser.isAdmin) {
          that.removeAllStudentsFromTeacher({
            teacherId: that.getCurrentAdminItem.teacherId,
            yearId: that.getCurrentAdminItem.year.yearId,
            gradeLevel: (that.showLevel.value !== '-999') ? that.showLevel.value : ''
          }).then(() => that.init())
        } else {
          that.removeAllStudentsFromClass({
            subjectId: that.selectedClass.value,
            gradeLevel: (that.showLevel.value !== '-999') ? that.showLevel.value : ''
          }).then(() => that.init())
        }
      }
    });
  }

  assignSelected() {
    if (CommonUtils.isEmpty(this.selectedUnassignedRows)) {
      return;
    }
    for (const i in this.selectedUnassignedRows) {
      if (this.$currentUser.isAdmin) {
        this.assignStudentToTeacher({
          teacherId: this.getCurrentAdminItem.teacherId,
          yearId: this.getCurrentAdminItem.year.yearId,
          studentId: this.selectedUnassignedRows[i].studentId
        }).then(() => this.init())
      } else {
        this.assignStudentToClass({
          subjectId: this.selectedClass.value,
          studentId: this.selectedUnassignedRows[i].studentId
        }).then(() => this.init())
      }
    }
  }

  removeSelected() {
    if (CommonUtils.isEmpty(this.selectedAssignedRows)) {
      return;
    }
    for (const i in this.selectedAssignedRows) {
      if (this.$currentUser.isAdmin) {
        this.removeStudentFromTeacher({
          teacherId: this.getCurrentAdminItem.teacherId,
          yearId: this.getCurrentAdminItem.year.yearId,
          studentId: this.selectedAssignedRows[i].studentId
        }).then(() => this.init())
      } else {
        this.removeStudentFromClass({
          subjectId: this.selectedClass.value,
          studentId: this.selectedAssignedRows[i].studentId
        }).then(() => this.init())
      }
    }
  }

  get filteredUnassignedStudents() {
    return this.unassignedStudents.filter((s: any) => {
      return this.showLevel.value === '-999' || s.gradeLevel === +this.showLevel.value;
    }).map((s:any) => {
      if (this.$currentUser.isTeacher) {
        s.subjectId = this.selectedRemoveSubjectId ? this.selectedRemoveSubjectId : this.selectedClass.value;
        s.isItemRostered = this.isStudentEnrollmentRostered(s);
      }
      return s;
    })
  }

  set filteredUnassignedStudents(students: any) {
    students.map((s: any) => {
      if (!this.unassignedStudents.find((student: any) => s.studentId === student.studentId)) {
        this.unassignedStudents.push(s);
        this.unassignedStudents.sort((a: any, b: any) => {
          if (a.lastName.toUpperCase() < b.lastName.toUpperCase()) { return -1; }
          if (a.lastName.toUpperCase() > b.lastName.toUpperCase()) { return 1; }
          return 0;
        })
      }
    })
  }

  async unassignStudent(event: any) {
    if (ld.isEqual(event.from, event.to)) {
      return;
    }
    const studentId = event.item._underlying_vm_.studentId;
    if (this.$currentUser.isAdmin) {
      await this.removeStudentFromTeacher({
        teacherId: this.getCurrentAdminItem.teacherId,
        yearId: this.getCurrentAdminItem.year.yearId,
        studentId: studentId
      });
    } else {
      await this.removeStudentFromClass({
        subjectId: this.selectedClass.value,
        studentId: studentId
      })
    }
    this.selectedAssignedRows = this.selectedAssignedRows.filter((a: any) => a.studentId !== studentId);
  }

  assignStudent(event: any) {
    if (ld.isEqual(event.from, event.to)) {
      return;
    }
    const studentId = event.item._underlying_vm_.studentId;
    if (this.$currentUser.isAdmin) {
      this.assignStudentToTeacher({
        teacherId: this.getCurrentAdminItem.teacherId,
        yearId: this.getCurrentAdminItem.year.yearId,
        studentId: studentId
      });
    } else {
      this.assignStudentToClass({
        subjectId: this.selectedClass.value,
        studentId: studentId
      })
    }
    this.assignedStudents.sort((a: any, b: any) => {
      if (a.lastName.toUpperCase() < b.lastName.toUpperCase()) { return -1; }
      if (a.lastName.toUpperCase() > b.lastName.toUpperCase()) { return 1; }
      return 0;
    })
    this.unassignedStudents = this.unassignedStudents.filter((a: any) => a.studentId !== studentId);
    this.selectedUnassignedRows = this.selectedUnassignedRows.filter((a: any) => a.studentId !== studentId);
  }

  isMenuOverlapping(browserWidth?:number) {
    if (this.lastBrowserWidth !== 0 && (browserWidth || 0) < this.lastBrowserWidth) {
      this.localIsMenuOverlapping = true;
      return;
    }
    if ((browserWidth || 0) > this.lastBrowserWidth) {
      this.localIsMenuOverlapping = false;
      this.lastBrowserWidth = 0;
      return;
    }
    setTimeout(() => {
      const toolbarTitle:any = document.getElementById('toolbarTitle');
      const titleRect = toolbarTitle.getBoundingClientRect();
      const elementToCheckIfOverlapping:any = document.getElementById('studentsGradeLevelSelect');
      if (elementToCheckIfOverlapping) {
        const elementToCheckIfOverlappingRect = elementToCheckIfOverlapping.getBoundingClientRect();
        if (
          titleRect.top < elementToCheckIfOverlappingRect.bottom &&
            titleRect.bottom > elementToCheckIfOverlappingRect.top &&
            titleRect.left < elementToCheckIfOverlappingRect.right &&
            titleRect.right > elementToCheckIfOverlappingRect.left
        ) {
          this.lastBrowserWidth = this.browserWidth;
          this.localIsMenuOverlapping = true;
          return true;
        }
        this.localIsMenuOverlapping = false;
        return false;
      } else {
        this.localIsMenuOverlapping = false;
        return false;
      }
    }, 50);
    this.localIsMenuOverlapping = false;
    return false;
  }
}
